.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--dark {
  background: #121212;
  color: #fff;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #07bc0c;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: #e74c3c;
}
.Toastify__toast-body {
  margin: auto 0;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}
.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  -ms-flex-item-align: start;
      align-self: flex-start;
}
.Toastify__close-button--default {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}
.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}
.Toastify__progress-bar--dark {
  background: #bb86fc;
}
@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}
.ReactTable {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.ReactTable * {
  box-sizing: border-box;
}
.ReactTable .rt-table {
  -webkit-box-flex: 1;
  -ms-flex: auto 1;
  flex: auto 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}
.ReactTable .rt-thead {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
}
.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}
.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}
.ReactTable .rt-thead .rt-tr {
  text-align: center;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}
.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-thead .rt-th:focus {
  outline-width: 0;
}
.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}
.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}
.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}
.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}
.ReactTable .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}
.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}
.ReactTable .rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.ReactTable .rt-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.ReactTable .rt-th,
.ReactTable .rt-td {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}
.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}
.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}
.ReactTable .rt-expander:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}
.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}
.ReactTable .rt-tfoot {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}
.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}
.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05);
}
.ReactTable .-pagination {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
}
.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline-width: 0;
}
.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}
.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}
.ReactTable .-pagination .-center {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}
.ReactTable .-pagination .-pageJump {
  display: inline-block;
}
.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}
.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}
.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}
.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}
.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.list {
  border: var(--border);
  border-radius: var(--border-radius); }
  .list .list-item {
    padding: var(--padding-small);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: var(--transition); }
    .list .list-item:hover {
      background-color: var(--background-color-hover); }
    .list .list-item.list-item-selected {
      background-color: var(--background-color-selected); }

:root {
  /* =======================================

      TEXT

  ======================================= */
  --color: black;
  --color-header: rgba(0, 170, 250);
  --color-header-fine: rgba(0, 170, 250, 0.6);
  --color-header-raw: 0, 208, 214;
  --color-secondary: black;
  --color-fine: rgba(0, 0, 0, 0.6);
  --text-bigger: 28px;
  --text-big: 22px;
  --text-normal: 15px;
  /* =======================================

      COLORS

  ======================================= */
  --success: rgb(25, 145, 14);
  --warning: rgba(255, 251, 33, 1);
  --error: rgba(255, 64, 80, 1);
  --disabled: rgba(100, 100, 100, 0.6);
  --background-color: rgb(230, 255, 255);
  --background-color-item: white;
  --background-color-hover: rgba(0, 0, 0, 0.2);
  --background-color-selected: rgba(0, 0, 0, 0.3);
  --background-color-disabled: rgba(0, 0, 0, 0.6);
  --background-color-highlighted: rgba(0, 0, 0, 0.1);
  --background-color-item-popup: rgba(230, 230, 230, 1);
  /* =======================================

      STYLE

  ======================================= */
  --border-radius: 5px;
  --border-radius-small: 2px;
  --border-width: 1px;
  --border-color-strong: rgba(0, 0, 0, 0.6);
  --border: 1px solid rgba(0, 0, 0, 0.3);
  --shadow-color: var(--color-header);
  --shadow: 0px 0px 10px var(--shadow-color);
  --inner-shadow: inset 0px 0px 10px var(--shadow-color);
  --inner-shadow-big: inset 0px 0px 20px var(--shadow-color);
  --padding: 10px;
  --padding-small: 5px;
  --padding-smaller: 3px;
  --margin: 5px;
  --margin-big: 10px;
  --margin-small: 3px;
  /* =======================================

      ANIMATIONS

  ======================================= */
  --transition: 0.15s all ease-in-out;
  --fast-transition: 0.075s all ease-in-out;
  --slow-transition: 0.3s all ease-in-out; }

@media (prefers-color-scheme: dark) {
  :root {
    /* =======================================

        TEXT

    ======================================= */
    --color: white;
    --color-header: rgba(0, 208, 214);
    --color-header-fine: rgba(0, 208, 214, 0.6);
    --color-header-raw: 0, 208, 214;
    --color-secondary: black;
    --color-fine: rgba(255, 255, 255, 0.6);
    --text-bigger: 28px;
    --text-big: 22px;
    --text-normal: 15px;
    /* =======================================

        COLORS

    ======================================= */
    --success: rgb(25, 145, 14);
    --warning: rgba(255, 251, 33, 1);
    --error: rgba(255, 64, 80, 1);
    --disabled: rgba(100, 100, 100, 0.6);
    --background-color: black;
    --background-color-item: rgb(33, 44, 44);
    --background-color-hover: rgba(255, 255, 255, 0.2);
    --background-color-selected: rgba(255, 255, 255, 0.3);
    --background-color-disabled: rgba(0, 0, 0, 0.6);
    --background-color-highlighted: rgba(255, 255, 255, 0.1);
    --background-color-item-popup: rgba(100, 100, 100, 1);
    /* =======================================

        STYLE

    ======================================= */
    --border-radius: 5px;
    --border-radius-small: 2px;
    --border-width: 1px;
    --border-color-strong: rgba(255, 255, 255, 0.6);
    --border: 1px solid rgba(255, 255, 255, 0.3);
    --shadow-color: var(--color-header);
    --shadow: 0px 0px 2px var(--shadow-color);
    --inner-shadow: inset 0px 0px 2px var(--shadow-color);
    --padding-big: 15px;
    --padding: 10px;
    --padding-small: 5px;
    --padding-smaller: 3px;
    --margin: 5px;
    --margin-big: 10px;
    --margin-small: 3px;
    /* =======================================

        ANIMATIONS

    ======================================= */
    --transition: 0.15s all ease-in-out;
    --fast-transition: 0.075s all ease-in-out;
    --slow-transition: 0.3s all ease-in-out; } }

.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  min-height: 50px;
  z-index: 10; }
  .spinner .spinner-animation {
    display: flex;
    justify-content: center; }
    .spinner .spinner-animation > div {
      width: 22px;
      height: 22px; }
      .spinner .spinner-animation > div > div {
        width: 0px;
        height: 0px;
        margin: var(--margin-small);
        border-radius: 50px;
        border: 10px solid var(--color-header); }
      .spinner .spinner-animation > div .spinner-1 {
        animation: loading 1s linear 0s infinite; }
      .spinner .spinner-animation > div .spinner-2 {
        animation: loading 1s linear 333ms infinite; }
      .spinner .spinner-animation > div .spinner-3 {
        animation: loading 1s linear 666ms infinite; }

@keyframes loading {
  0% {
    border-width: 10px;
    width: 0px;
    height: 0px;
    border-color: rgba(var(--color-header-raw), 1); }
  50% {
    border-width: 1px;
    width: 10px;
    height: 10px;
    border-color: rgba(var(--color-header-raw), 0.1); }
  100% {
    border-width: 9px;
    width: 1px;
    height: 1px;
    border-color: rgba(var(--color-header-raw), 0.95); } }

:root {
  /* =======================================

      TEXT

  ======================================= */
  --color: black;
  --color-header: rgba(0, 170, 250);
  --color-header-fine: rgba(0, 170, 250, 0.6);
  --color-header-raw: 0, 208, 214;
  --color-secondary: black;
  --color-fine: rgba(0, 0, 0, 0.6);
  --text-bigger: 28px;
  --text-big: 22px;
  --text-normal: 15px;
  /* =======================================

      COLORS

  ======================================= */
  --success: rgb(25, 145, 14);
  --warning: rgba(255, 251, 33, 1);
  --error: rgba(255, 64, 80, 1);
  --disabled: rgba(100, 100, 100, 0.6);
  --background-color: rgb(230, 255, 255);
  --background-color-item: white;
  --background-color-hover: rgba(0, 0, 0, 0.2);
  --background-color-selected: rgba(0, 0, 0, 0.3);
  --background-color-disabled: rgba(0, 0, 0, 0.6);
  --background-color-highlighted: rgba(0, 0, 0, 0.1);
  --background-color-item-popup: rgba(230, 230, 230, 1);
  /* =======================================

      STYLE

  ======================================= */
  --border-radius: 5px;
  --border-radius-small: 2px;
  --border-width: 1px;
  --border-color-strong: rgba(0, 0, 0, 0.6);
  --border: 1px solid rgba(0, 0, 0, 0.3);
  --shadow-color: var(--color-header);
  --shadow: 0px 0px 10px var(--shadow-color);
  --inner-shadow: inset 0px 0px 10px var(--shadow-color);
  --inner-shadow-big: inset 0px 0px 20px var(--shadow-color);
  --padding: 10px;
  --padding-small: 5px;
  --padding-smaller: 3px;
  --margin: 5px;
  --margin-big: 10px;
  --margin-small: 3px;
  /* =======================================

      ANIMATIONS

  ======================================= */
  --transition: 0.15s all ease-in-out;
  --fast-transition: 0.075s all ease-in-out;
  --slow-transition: 0.3s all ease-in-out; }

@media (prefers-color-scheme: dark) {
  :root {
    /* =======================================

        TEXT

    ======================================= */
    --color: white;
    --color-header: rgba(0, 208, 214);
    --color-header-fine: rgba(0, 208, 214, 0.6);
    --color-header-raw: 0, 208, 214;
    --color-secondary: black;
    --color-fine: rgba(255, 255, 255, 0.6);
    --text-bigger: 28px;
    --text-big: 22px;
    --text-normal: 15px;
    /* =======================================

        COLORS

    ======================================= */
    --success: rgb(25, 145, 14);
    --warning: rgba(255, 251, 33, 1);
    --error: rgba(255, 64, 80, 1);
    --disabled: rgba(100, 100, 100, 0.6);
    --background-color: black;
    --background-color-item: rgb(33, 44, 44);
    --background-color-hover: rgba(255, 255, 255, 0.2);
    --background-color-selected: rgba(255, 255, 255, 0.3);
    --background-color-disabled: rgba(0, 0, 0, 0.6);
    --background-color-highlighted: rgba(255, 255, 255, 0.1);
    --background-color-item-popup: rgba(100, 100, 100, 1);
    /* =======================================

        STYLE

    ======================================= */
    --border-radius: 5px;
    --border-radius-small: 2px;
    --border-width: 1px;
    --border-color-strong: rgba(255, 255, 255, 0.6);
    --border: 1px solid rgba(255, 255, 255, 0.3);
    --shadow-color: var(--color-header);
    --shadow: 0px 0px 2px var(--shadow-color);
    --inner-shadow: inset 0px 0px 2px var(--shadow-color);
    --padding-big: 15px;
    --padding: 10px;
    --padding-small: 5px;
    --padding-smaller: 3px;
    --margin: 5px;
    --margin-big: 10px;
    --margin-small: 3px;
    /* =======================================

        ANIMATIONS

    ======================================= */
    --transition: 0.15s all ease-in-out;
    --fast-transition: 0.075s all ease-in-out;
    --slow-transition: 0.3s all ease-in-out; } }

.dialog {
  top: 0;
  left: 0;
  z-index: 10;
  display: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color-disabled); }
  .dialog .dialog-content {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    height: auto;
    background-color: var(--background-color-item);
    max-height: 70vh;
    min-height: 100px;
    max-width: 70vw;
    min-width: 200px;
    padding: var(--padding); }
    @media (max-width: 500px) {
      .dialog .dialog-content {
        max-width: 90vw; } }
    .dialog .dialog-content .dialog-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      box-shadow: none; }
    .dialog .dialog-content .dialog-title {
      color: var(--color-header);
      font-size: var(--text-bigger);
      border-bottom: var(--border-width) solid var(--border-color-strong);
      text-align: center;
      padding-bottom: var(--padding);
      margin-bottom: var(--margin-big); }
    .dialog .dialog-content .dialog-wrapper {
      display: flex;
      grid-template-columns: 100%;
      overflow: hidden auto; }
      .dialog .dialog-content .dialog-wrapper .dialog-children {
        padding: var(--padding);
        width: 100%;
        overflow: hidden auto; }
    .dialog .dialog-content .dialog-close-btn:hover {
      background-color: var(--background-color-hover); }

.dialog-open {
  display: flex;
  animation: dialog-open 0.3s linear; }

.dialog-closing {
  animation: dialog-closing 0.3s linear; }

@keyframes dialog-open {
  0% {
    top: 50vh;
    left: 50vw;
    width: 0vw;
    height: 0vh; }
  100% {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; } }

@keyframes dialog-closing {
  0% {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
  100% {
    top: 50vh;
    left: 50vw;
    width: 0vw;
    height: 0vh; } }

:root {
  /* =======================================

      TEXT

  ======================================= */
  --color: black;
  --color-header: rgba(0, 170, 250);
  --color-header-fine: rgba(0, 170, 250, 0.6);
  --color-header-raw: 0, 208, 214;
  --color-secondary: black;
  --color-fine: rgba(0, 0, 0, 0.6);
  --text-bigger: 28px;
  --text-big: 22px;
  --text-normal: 15px;
  /* =======================================

      COLORS

  ======================================= */
  --success: rgb(25, 145, 14);
  --warning: rgba(255, 251, 33, 1);
  --error: rgba(255, 64, 80, 1);
  --disabled: rgba(100, 100, 100, 0.6);
  --background-color: rgb(230, 255, 255);
  --background-color-item: white;
  --background-color-hover: rgba(0, 0, 0, 0.2);
  --background-color-selected: rgba(0, 0, 0, 0.3);
  --background-color-disabled: rgba(0, 0, 0, 0.6);
  --background-color-highlighted: rgba(0, 0, 0, 0.1);
  --background-color-item-popup: rgba(230, 230, 230, 1);
  /* =======================================

      STYLE

  ======================================= */
  --border-radius: 5px;
  --border-radius-small: 2px;
  --border-width: 1px;
  --border-color-strong: rgba(0, 0, 0, 0.6);
  --border: 1px solid rgba(0, 0, 0, 0.3);
  --shadow-color: var(--color-header);
  --shadow: 0px 0px 10px var(--shadow-color);
  --inner-shadow: inset 0px 0px 10px var(--shadow-color);
  --inner-shadow-big: inset 0px 0px 20px var(--shadow-color);
  --padding: 10px;
  --padding-small: 5px;
  --padding-smaller: 3px;
  --margin: 5px;
  --margin-big: 10px;
  --margin-small: 3px;
  /* =======================================

      ANIMATIONS

  ======================================= */
  --transition: 0.15s all ease-in-out;
  --fast-transition: 0.075s all ease-in-out;
  --slow-transition: 0.3s all ease-in-out; }

@media (prefers-color-scheme: dark) {
  :root {
    /* =======================================

        TEXT

    ======================================= */
    --color: white;
    --color-header: rgba(0, 208, 214);
    --color-header-fine: rgba(0, 208, 214, 0.6);
    --color-header-raw: 0, 208, 214;
    --color-secondary: black;
    --color-fine: rgba(255, 255, 255, 0.6);
    --text-bigger: 28px;
    --text-big: 22px;
    --text-normal: 15px;
    /* =======================================

        COLORS

    ======================================= */
    --success: rgb(25, 145, 14);
    --warning: rgba(255, 251, 33, 1);
    --error: rgba(255, 64, 80, 1);
    --disabled: rgba(100, 100, 100, 0.6);
    --background-color: black;
    --background-color-item: rgb(33, 44, 44);
    --background-color-hover: rgba(255, 255, 255, 0.2);
    --background-color-selected: rgba(255, 255, 255, 0.3);
    --background-color-disabled: rgba(0, 0, 0, 0.6);
    --background-color-highlighted: rgba(255, 255, 255, 0.1);
    --background-color-item-popup: rgba(100, 100, 100, 1);
    /* =======================================

        STYLE

    ======================================= */
    --border-radius: 5px;
    --border-radius-small: 2px;
    --border-width: 1px;
    --border-color-strong: rgba(255, 255, 255, 0.6);
    --border: 1px solid rgba(255, 255, 255, 0.3);
    --shadow-color: var(--color-header);
    --shadow: 0px 0px 2px var(--shadow-color);
    --inner-shadow: inset 0px 0px 2px var(--shadow-color);
    --padding-big: 15px;
    --padding: 10px;
    --padding-small: 5px;
    --padding-smaller: 3px;
    --margin: 5px;
    --margin-big: 10px;
    --margin-small: 3px;
    /* =======================================

        ANIMATIONS

    ======================================= */
    --transition: 0.15s all ease-in-out;
    --fast-transition: 0.075s all ease-in-out;
    --slow-transition: 0.3s all ease-in-out; } }

.kl-button {
  cursor: pointer;
  border-radius: var(--border-radius);
  color: var(--color);
  background-color: var(--background-color-item);
  border: var(--border-width) solid var(--background-color-item);
  transition: var(--transition);
  padding: var(--padding);
  box-shadow: var(--shadow);
  margin: var(--margin);
  outline: none; }

.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px; }
  .icon-button i {
    transition: var(--transition); }

.icon-button:hover .left-rotate {
  transform: rotate(-90deg); }

.icon-button:hover .right-rotate {
  transform: rotate(90deg); }

.kl-button:hover {
  color: var(--color);
  background-color: transparent; }

.kl-button-success {
  background-color: var(--success);
  border: var(--border-width) solid var(--success); }

.kl-button-warning {
  color: var(--color-secondary);
  background-color: var(--warning);
  border: var(--border-width) solid var(--warning); }

.kl-button-error {
  background-color: var(--error);
  border: var(--border-width) solid var(--error); }

.kl-button-transparent {
  background-color: transparent;
  border: none;
  box-shadow: none; }
  .kl-button-transparent:hover {
    background-color: var(--background-color-hover); }

.game {
  width: 500px;
  height: 500px;
  background-color: black;
  border: 2px solid white; }
  .game .game-winner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center; }
    .game .game-winner .game-winner-title {
      font-size: 3rem; }
    .game .game-winner .game-winner-reset {
      border-radius: var(--border-radius);
      padding: 5px;
      background-color: var(--error);
      border: 1px solid var(--error);
      color: var(--color);
      cursor: pointer;
      transition: var(--transition); }
      .game .game-winner .game-winner-reset:hover {
        background-color: rgba(0, 0, 0, 0); }
    .game .game-winner i {
      margin: 20px 0;
      font-size: 1000%; }
    .game .game-winner .player-1 {
      color: green; }
    .game .game-winner .player-0 {
      color: red; }
    .game .game-winner .player-none {
      color: gray; }

.tic-tac-toe-field {
  display: grid;
  grid-template-columns: 33% 33% auto;
  grid-template-rows: 33% 33% auto;
  width: 100%;
  height: 100%;
  border: 1px solid white; }
  .tic-tac-toe-field .player-1 {
    color: green; }
  .tic-tac-toe-field .player-0 {
    color: red; }
  .tic-tac-toe-field .player-none {
    color: gray; }

.tic-tac-toe-field-0 {
  display: flex; }
  .tic-tac-toe-field-0 .tic-tac-toe-clickable {
    border: 0.5px solid rgba(128, 128, 128, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; }

.tic-tac-toe-markup {
  box-shadow: inset 0 0 12px blue; }

.tic-tac-toe-field-success {
  display: flex;
  width: 100%;
  height: 100%; }
  .tic-tac-toe-field-success .tic-tac-toe-success {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
    .tic-tac-toe-field-success .tic-tac-toe-success i {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%; }

.logout {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .logout .logout-message {
    font-size: var(--text-bigger); }
  .logout .logout-icon {
    padding: var(--padding); }
    .logout .logout-icon i {
      font-size: calc(var(--text-bigger) * 2);
      color: var(--success); }

:root {
  /* =======================================

      TEXT

  ======================================= */
  --color: black;
  --color-header: rgba(0, 170, 250);
  --color-header-fine: rgba(0, 170, 250, 0.6);
  --color-header-raw: 0, 208, 214;
  --color-secondary: black;
  --color-fine: rgba(0, 0, 0, 0.6);
  --text-bigger: 28px;
  --text-big: 22px;
  --text-normal: 15px;
  /* =======================================

      COLORS

  ======================================= */
  --success: rgb(25, 145, 14);
  --warning: rgba(255, 251, 33, 1);
  --error: rgba(255, 64, 80, 1);
  --disabled: rgba(100, 100, 100, 0.6);
  --background-color: rgb(230, 255, 255);
  --background-color-item: white;
  --background-color-hover: rgba(0, 0, 0, 0.2);
  --background-color-selected: rgba(0, 0, 0, 0.3);
  --background-color-disabled: rgba(0, 0, 0, 0.6);
  --background-color-highlighted: rgba(0, 0, 0, 0.1);
  --background-color-item-popup: rgba(230, 230, 230, 1);
  /* =======================================

      STYLE

  ======================================= */
  --border-radius: 5px;
  --border-radius-small: 2px;
  --border-width: 1px;
  --border-color-strong: rgba(0, 0, 0, 0.6);
  --border: 1px solid rgba(0, 0, 0, 0.3);
  --shadow-color: var(--color-header);
  --shadow: 0px 0px 10px var(--shadow-color);
  --inner-shadow: inset 0px 0px 10px var(--shadow-color);
  --inner-shadow-big: inset 0px 0px 20px var(--shadow-color);
  --padding: 10px;
  --padding-small: 5px;
  --padding-smaller: 3px;
  --margin: 5px;
  --margin-big: 10px;
  --margin-small: 3px;
  /* =======================================

      ANIMATIONS

  ======================================= */
  --transition: 0.15s all ease-in-out;
  --fast-transition: 0.075s all ease-in-out;
  --slow-transition: 0.3s all ease-in-out; }

@media (prefers-color-scheme: dark) {
  :root {
    /* =======================================

        TEXT

    ======================================= */
    --color: white;
    --color-header: rgba(0, 208, 214);
    --color-header-fine: rgba(0, 208, 214, 0.6);
    --color-header-raw: 0, 208, 214;
    --color-secondary: black;
    --color-fine: rgba(255, 255, 255, 0.6);
    --text-bigger: 28px;
    --text-big: 22px;
    --text-normal: 15px;
    /* =======================================

        COLORS

    ======================================= */
    --success: rgb(25, 145, 14);
    --warning: rgba(255, 251, 33, 1);
    --error: rgba(255, 64, 80, 1);
    --disabled: rgba(100, 100, 100, 0.6);
    --background-color: black;
    --background-color-item: rgb(33, 44, 44);
    --background-color-hover: rgba(255, 255, 255, 0.2);
    --background-color-selected: rgba(255, 255, 255, 0.3);
    --background-color-disabled: rgba(0, 0, 0, 0.6);
    --background-color-highlighted: rgba(255, 255, 255, 0.1);
    --background-color-item-popup: rgba(100, 100, 100, 1);
    /* =======================================

        STYLE

    ======================================= */
    --border-radius: 5px;
    --border-radius-small: 2px;
    --border-width: 1px;
    --border-color-strong: rgba(255, 255, 255, 0.6);
    --border: 1px solid rgba(255, 255, 255, 0.3);
    --shadow-color: var(--color-header);
    --shadow: 0px 0px 2px var(--shadow-color);
    --inner-shadow: inset 0px 0px 2px var(--shadow-color);
    --padding-big: 15px;
    --padding: 10px;
    --padding-small: 5px;
    --padding-smaller: 3px;
    --margin: 5px;
    --margin-big: 10px;
    --margin-small: 3px;
    /* =======================================

        ANIMATIONS

    ======================================= */
    --transition: 0.15s all ease-in-out;
    --fast-transition: 0.075s all ease-in-out;
    --slow-transition: 0.3s all ease-in-out; } }

.tile-holder {
  color: var(--color);
  display: flex;
  height: calc(100% - var(--padding) * 2);
  width: calc(100% - var(--padding) * 2);
  flex-wrap: wrap;
  padding: var(--padding);
  overflow: scroll;
  align-content: flex-start; }
  @media screen and (max-width: 500px) {
    .tile-holder {
      padding: 0;
      height: 100%;
      width: 100%; } }
  .tile-holder .tile {
    margin: var(--margin);
    display: flex;
    flex-direction: column;
    width: auto;
    height: min-content;
    padding: var(--padding);
    box-shadow: var(--shadow);
    background-color: var(--background-color-item);
    border-radius: var(--border-radius); }
    .tile-holder .tile.tile-table {
      width: calc(100% - var(--padding) * 2); }
      @media (max-width: 500px) {
        .tile-holder .tile.tile-table {
          min-width: 100%; } }
    .tile-holder .tile .tile-title {
      color: var(--color-header);
      padding-bottom: var(--padding);
      margin-bottom: var(--margin);
      border-bottom: var(--border-width) solid var(--border-color-strong); }

.tile-form {
  margin: var(--margin) auto; }

:root {
  /* =======================================

      TEXT

  ======================================= */
  --color: black;
  --color-header: rgba(0, 170, 250);
  --color-header-fine: rgba(0, 170, 250, 0.6);
  --color-header-raw: 0, 208, 214;
  --color-secondary: black;
  --color-fine: rgba(0, 0, 0, 0.6);
  --text-bigger: 28px;
  --text-big: 22px;
  --text-normal: 15px;
  /* =======================================

      COLORS

  ======================================= */
  --success: rgb(25, 145, 14);
  --warning: rgba(255, 251, 33, 1);
  --error: rgba(255, 64, 80, 1);
  --disabled: rgba(100, 100, 100, 0.6);
  --background-color: rgb(230, 255, 255);
  --background-color-item: white;
  --background-color-hover: rgba(0, 0, 0, 0.2);
  --background-color-selected: rgba(0, 0, 0, 0.3);
  --background-color-disabled: rgba(0, 0, 0, 0.6);
  --background-color-highlighted: rgba(0, 0, 0, 0.1);
  --background-color-item-popup: rgba(230, 230, 230, 1);
  /* =======================================

      STYLE

  ======================================= */
  --border-radius: 5px;
  --border-radius-small: 2px;
  --border-width: 1px;
  --border-color-strong: rgba(0, 0, 0, 0.6);
  --border: 1px solid rgba(0, 0, 0, 0.3);
  --shadow-color: var(--color-header);
  --shadow: 0px 0px 10px var(--shadow-color);
  --inner-shadow: inset 0px 0px 10px var(--shadow-color);
  --inner-shadow-big: inset 0px 0px 20px var(--shadow-color);
  --padding: 10px;
  --padding-small: 5px;
  --padding-smaller: 3px;
  --margin: 5px;
  --margin-big: 10px;
  --margin-small: 3px;
  /* =======================================

      ANIMATIONS

  ======================================= */
  --transition: 0.15s all ease-in-out;
  --fast-transition: 0.075s all ease-in-out;
  --slow-transition: 0.3s all ease-in-out; }

@media (prefers-color-scheme: dark) {
  :root {
    /* =======================================

        TEXT

    ======================================= */
    --color: white;
    --color-header: rgba(0, 208, 214);
    --color-header-fine: rgba(0, 208, 214, 0.6);
    --color-header-raw: 0, 208, 214;
    --color-secondary: black;
    --color-fine: rgba(255, 255, 255, 0.6);
    --text-bigger: 28px;
    --text-big: 22px;
    --text-normal: 15px;
    /* =======================================

        COLORS

    ======================================= */
    --success: rgb(25, 145, 14);
    --warning: rgba(255, 251, 33, 1);
    --error: rgba(255, 64, 80, 1);
    --disabled: rgba(100, 100, 100, 0.6);
    --background-color: black;
    --background-color-item: rgb(33, 44, 44);
    --background-color-hover: rgba(255, 255, 255, 0.2);
    --background-color-selected: rgba(255, 255, 255, 0.3);
    --background-color-disabled: rgba(0, 0, 0, 0.6);
    --background-color-highlighted: rgba(255, 255, 255, 0.1);
    --background-color-item-popup: rgba(100, 100, 100, 1);
    /* =======================================

        STYLE

    ======================================= */
    --border-radius: 5px;
    --border-radius-small: 2px;
    --border-width: 1px;
    --border-color-strong: rgba(255, 255, 255, 0.6);
    --border: 1px solid rgba(255, 255, 255, 0.3);
    --shadow-color: var(--color-header);
    --shadow: 0px 0px 2px var(--shadow-color);
    --inner-shadow: inset 0px 0px 2px var(--shadow-color);
    --padding-big: 15px;
    --padding: 10px;
    --padding-small: 5px;
    --padding-smaller: 3px;
    --margin: 5px;
    --margin-big: 10px;
    --margin-small: 3px;
    /* =======================================

        ANIMATIONS

    ======================================= */
    --transition: 0.15s all ease-in-out;
    --fast-transition: 0.075s all ease-in-out;
    --slow-transition: 0.3s all ease-in-out; } }

.cookie-banner {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: var(--background-color-item);
  top: 0;
  left: 0; }
  .cookie-banner .cookie-banner-title {
    font-size: var(--text-bigger);
    padding: var(--padding);
    color: var(--color-header);
    text-align: center; }
  .cookie-banner .cookie-banner-content {
    padding: var(--padding); }
  .cookie-banner .cookie-banner-footer {
    border-top: var(--border);
    padding: var(--padding);
    display: flex;
    width: calc(100% - var(--padding) * 2);
    flex-direction: row;
    justify-content: flex-end; }

:root {
  /* =======================================

      TEXT

  ======================================= */
  --color: black;
  --color-header: rgba(0, 170, 250);
  --color-header-fine: rgba(0, 170, 250, 0.6);
  --color-header-raw: 0, 208, 214;
  --color-secondary: black;
  --color-fine: rgba(0, 0, 0, 0.6);
  --text-bigger: 28px;
  --text-big: 22px;
  --text-normal: 15px;
  /* =======================================

      COLORS

  ======================================= */
  --success: rgb(25, 145, 14);
  --warning: rgba(255, 251, 33, 1);
  --error: rgba(255, 64, 80, 1);
  --disabled: rgba(100, 100, 100, 0.6);
  --background-color: rgb(230, 255, 255);
  --background-color-item: white;
  --background-color-hover: rgba(0, 0, 0, 0.2);
  --background-color-selected: rgba(0, 0, 0, 0.3);
  --background-color-disabled: rgba(0, 0, 0, 0.6);
  --background-color-highlighted: rgba(0, 0, 0, 0.1);
  --background-color-item-popup: rgba(230, 230, 230, 1);
  /* =======================================

      STYLE

  ======================================= */
  --border-radius: 5px;
  --border-radius-small: 2px;
  --border-width: 1px;
  --border-color-strong: rgba(0, 0, 0, 0.6);
  --border: 1px solid rgba(0, 0, 0, 0.3);
  --shadow-color: var(--color-header);
  --shadow: 0px 0px 10px var(--shadow-color);
  --inner-shadow: inset 0px 0px 10px var(--shadow-color);
  --inner-shadow-big: inset 0px 0px 20px var(--shadow-color);
  --padding: 10px;
  --padding-small: 5px;
  --padding-smaller: 3px;
  --margin: 5px;
  --margin-big: 10px;
  --margin-small: 3px;
  /* =======================================

      ANIMATIONS

  ======================================= */
  --transition: 0.15s all ease-in-out;
  --fast-transition: 0.075s all ease-in-out;
  --slow-transition: 0.3s all ease-in-out; }

@media (prefers-color-scheme: dark) {
  :root {
    /* =======================================

        TEXT

    ======================================= */
    --color: white;
    --color-header: rgba(0, 208, 214);
    --color-header-fine: rgba(0, 208, 214, 0.6);
    --color-header-raw: 0, 208, 214;
    --color-secondary: black;
    --color-fine: rgba(255, 255, 255, 0.6);
    --text-bigger: 28px;
    --text-big: 22px;
    --text-normal: 15px;
    /* =======================================

        COLORS

    ======================================= */
    --success: rgb(25, 145, 14);
    --warning: rgba(255, 251, 33, 1);
    --error: rgba(255, 64, 80, 1);
    --disabled: rgba(100, 100, 100, 0.6);
    --background-color: black;
    --background-color-item: rgb(33, 44, 44);
    --background-color-hover: rgba(255, 255, 255, 0.2);
    --background-color-selected: rgba(255, 255, 255, 0.3);
    --background-color-disabled: rgba(0, 0, 0, 0.6);
    --background-color-highlighted: rgba(255, 255, 255, 0.1);
    --background-color-item-popup: rgba(100, 100, 100, 1);
    /* =======================================

        STYLE

    ======================================= */
    --border-radius: 5px;
    --border-radius-small: 2px;
    --border-width: 1px;
    --border-color-strong: rgba(255, 255, 255, 0.6);
    --border: 1px solid rgba(255, 255, 255, 0.3);
    --shadow-color: var(--color-header);
    --shadow: 0px 0px 2px var(--shadow-color);
    --inner-shadow: inset 0px 0px 2px var(--shadow-color);
    --padding-big: 15px;
    --padding: 10px;
    --padding-small: 5px;
    --padding-smaller: 3px;
    --margin: 5px;
    --margin-big: 10px;
    --margin-small: 3px;
    /* =======================================

        ANIMATIONS

    ======================================= */
    --transition: 0.15s all ease-in-out;
    --fast-transition: 0.075s all ease-in-out;
    --slow-transition: 0.3s all ease-in-out; } }

.klumpp-table {
  display: flex;
  width: 100%;
  flex-direction: column;
  border: var(--border-width) solid var(--border-color-strong);
  border-radius: var(--border-radius); }
  .klumpp-table .klumpp-table-dialog {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .klumpp-table .klumpp-table-header {
    position: relative;
    display: flex;
    min-height: 35px;
    justify-content: center;
    padding: var(--padding); }
    .klumpp-table .klumpp-table-header .klumpp-table-title {
      cursor: default;
      color: var(--color-header);
      padding: var(--padding);
      margin-bottom: var(--margin-big);
      text-align: center; }
    .klumpp-table .klumpp-table-header .klumpp-table-add-button {
      position: absolute;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--success);
      border: var(--border-width) solid var(--success);
      transition: var(--transition);
      color: var(--color);
      border-radius: 50px;
      padding: var(--padding-small);
      cursor: pointer;
      bottom: var(--padding-small);
      left: var(--padding-small); }
      .klumpp-table .klumpp-table-header .klumpp-table-add-button:hover {
        background-color: transparent; }
  .klumpp-table .klumpp-table-content {
    /**/ }
    .klumpp-table .klumpp-table-content .ReactTable {
      border-radius: var(--border-radius);
      max-height: 500px; }
      .klumpp-table .klumpp-table-content .ReactTable .klumpp-table-no-data {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--text-bigger);
        width: 100%;
        height: 100%;
        background-color: var(--background-color-disabled);
        z-index: 2; }
      .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-thead {
        background-color: var(--background-color); }
        .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-thead .rt-tr .rt-th {
          padding: var(--padding-small);
          color: var(--color-header); }
          .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-thead .rt-tr .rt-th input {
            border: var(--border-width) solid var(--border-color-strong);
            background-color: transparent;
            border-radius: var(--border-radius);
            transition: var(--transition); }
            .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-thead .rt-tr .rt-th input:hover {
              background-color: var(--background-color-hover); }
      .klumpp-table .klumpp-table-content .ReactTable .rt-table .table-noData {
        color: var(--color);
        justify-content: center;
        align-items: center;
        font-size: var(--text-big);
        width: 100%;
        display: flex;
        height: 250px; }
      .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-tbody {
        background-color: var(--background-color-item);
        overflow-x: hidden; }
        .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-tbody .rt-tr-group {
          border-bottom: var(--border); }
          .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-tbody .rt-tr-group:last-child {
            border: none; }
          .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr {
            transition: var(--transition); }
            .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr:hover {
              background-color: var(--background-color-hover); }
            .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
              border-left: var(--border-width) solid var(--border-color-strong);
              padding: var(--padding-small);
              display: flex;
              align-items: center; }
              .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td:first-child {
                border-left: none; }
              .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td .checkbox-cell {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%; }
              .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td .delete-cell {
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center; }
                .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td .delete-cell button {
                  z-index: 2;
                  padding: var(--padding-smaller);
                  border-radius: 50px;
                  border: var(--border-width) solid var(--error);
                  color: var(--color);
                  cursor: pointer;
                  background-color: var(--error);
                  transition: var(--transition); }
                  .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td .delete-cell button:hover {
                    background-color: transparent; }
                  .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td .delete-cell button i {
                    font-size: var(--text-big); }
          .klumpp-table .klumpp-table-content .ReactTable .rt-table .rt-tbody .rt-tr-group .-padRow:hover {
            background-color: transparent; }
      .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom {
        display: flex;
        flex-direction: column;
        flex-flow: none; }
        .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination {
          display: flex;
          flex: none;
          justify-content: center; }
          .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination > * {
            display: flex;
            flex: none;
            padding: 8px; }
          .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination .-previous button,
          .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination .-next button {
            background-color: var(--success);
            border: var(--border-width) solid var(--success);
            color: var(--color);
            border-radius: var(--border-radius);
            transition: var(--transition);
            padding: var(--padding);
            width: 100px; }
            .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination .-previous button:hover,
            .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination .-next button:hover {
              cursor: pointer;
              background-color: transparent; }
            .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination .-previous button:disabled,
            .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination .-next button:disabled {
              cursor: default;
              border-color: #003200;
              background-color: #003200; }
          .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination .-previous {
            display: flex;
            justify-content: center;
            align-items: center; }
          .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination .-center {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center; }
            .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination .-center .form {
              margin-left: var(--margin); }
              .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination .-center .form .form-input {
                margin: 0; }
                .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination .-center .form .form-input input {
                  border: none;
                  background-color: transparent; }
                  .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination .-center .form .form-input input:hover {
                    background-color: var(--background-color-hover); }
          .klumpp-table .klumpp-table-content .ReactTable .pagination-bottom .-pagination .-next {
            display: flex;
            justify-content: center;
            align-items: center; }
      .klumpp-table .klumpp-table-content .ReactTable .spinner {
        position: absolute;
        background-color: var(--background-color-disabled); }

:root {
  /* =======================================

      TEXT

  ======================================= */
  --color: black;
  --color-header: rgba(0, 170, 250);
  --color-header-fine: rgba(0, 170, 250, 0.6);
  --color-header-raw: 0, 208, 214;
  --color-secondary: black;
  --color-fine: rgba(0, 0, 0, 0.6);
  --text-bigger: 28px;
  --text-big: 22px;
  --text-normal: 15px;
  /* =======================================

      COLORS

  ======================================= */
  --success: rgb(25, 145, 14);
  --warning: rgba(255, 251, 33, 1);
  --error: rgba(255, 64, 80, 1);
  --disabled: rgba(100, 100, 100, 0.6);
  --background-color: rgb(230, 255, 255);
  --background-color-item: white;
  --background-color-hover: rgba(0, 0, 0, 0.2);
  --background-color-selected: rgba(0, 0, 0, 0.3);
  --background-color-disabled: rgba(0, 0, 0, 0.6);
  --background-color-highlighted: rgba(0, 0, 0, 0.1);
  --background-color-item-popup: rgba(230, 230, 230, 1);
  /* =======================================

      STYLE

  ======================================= */
  --border-radius: 5px;
  --border-radius-small: 2px;
  --border-width: 1px;
  --border-color-strong: rgba(0, 0, 0, 0.6);
  --border: 1px solid rgba(0, 0, 0, 0.3);
  --shadow-color: var(--color-header);
  --shadow: 0px 0px 10px var(--shadow-color);
  --inner-shadow: inset 0px 0px 10px var(--shadow-color);
  --inner-shadow-big: inset 0px 0px 20px var(--shadow-color);
  --padding: 10px;
  --padding-small: 5px;
  --padding-smaller: 3px;
  --margin: 5px;
  --margin-big: 10px;
  --margin-small: 3px;
  /* =======================================

      ANIMATIONS

  ======================================= */
  --transition: 0.15s all ease-in-out;
  --fast-transition: 0.075s all ease-in-out;
  --slow-transition: 0.3s all ease-in-out; }

@media (prefers-color-scheme: dark) {
  :root {
    /* =======================================

        TEXT

    ======================================= */
    --color: white;
    --color-header: rgba(0, 208, 214);
    --color-header-fine: rgba(0, 208, 214, 0.6);
    --color-header-raw: 0, 208, 214;
    --color-secondary: black;
    --color-fine: rgba(255, 255, 255, 0.6);
    --text-bigger: 28px;
    --text-big: 22px;
    --text-normal: 15px;
    /* =======================================

        COLORS

    ======================================= */
    --success: rgb(25, 145, 14);
    --warning: rgba(255, 251, 33, 1);
    --error: rgba(255, 64, 80, 1);
    --disabled: rgba(100, 100, 100, 0.6);
    --background-color: black;
    --background-color-item: rgb(33, 44, 44);
    --background-color-hover: rgba(255, 255, 255, 0.2);
    --background-color-selected: rgba(255, 255, 255, 0.3);
    --background-color-disabled: rgba(0, 0, 0, 0.6);
    --background-color-highlighted: rgba(255, 255, 255, 0.1);
    --background-color-item-popup: rgba(100, 100, 100, 1);
    /* =======================================

        STYLE

    ======================================= */
    --border-radius: 5px;
    --border-radius-small: 2px;
    --border-width: 1px;
    --border-color-strong: rgba(255, 255, 255, 0.6);
    --border: 1px solid rgba(255, 255, 255, 0.3);
    --shadow-color: var(--color-header);
    --shadow: 0px 0px 2px var(--shadow-color);
    --inner-shadow: inset 0px 0px 2px var(--shadow-color);
    --padding-big: 15px;
    --padding: 10px;
    --padding-small: 5px;
    --padding-smaller: 3px;
    --margin: 5px;
    --margin-big: 10px;
    --margin-small: 3px;
    /* =======================================

        ANIMATIONS

    ======================================= */
    --transition: 0.15s all ease-in-out;
    --fast-transition: 0.075s all ease-in-out;
    --slow-transition: 0.3s all ease-in-out; } }

/*dds*/
.topBar {
  color: var(--color);
  width: 100%;
  background-color: var(--background-color-item);
  display: inline-grid;
  grid-template-columns: auto auto auto; }
  .topBar .top-bar-left {
    display: flex;
    justify-content: left;
    align-items: center; }
  .topBar .top-bar-center {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-header); }
  .topBar .top-bar-right {
    display: flex;
    justify-content: center;
    align-items: center; }
  .topBar button {
    background-color: transparent;
    border: none;
    color: var(--color);
    cursor: pointer; }
    .topBar button .material-icons {
      font-size: 2rem; }

:root {
  /* =======================================

      TEXT

  ======================================= */
  --color: black;
  --color-header: rgba(0, 170, 250);
  --color-header-fine: rgba(0, 170, 250, 0.6);
  --color-header-raw: 0, 208, 214;
  --color-secondary: black;
  --color-fine: rgba(0, 0, 0, 0.6);
  --text-bigger: 28px;
  --text-big: 22px;
  --text-normal: 15px;
  /* =======================================

      COLORS

  ======================================= */
  --success: rgb(25, 145, 14);
  --warning: rgba(255, 251, 33, 1);
  --error: rgba(255, 64, 80, 1);
  --disabled: rgba(100, 100, 100, 0.6);
  --background-color: rgb(230, 255, 255);
  --background-color-item: white;
  --background-color-hover: rgba(0, 0, 0, 0.2);
  --background-color-selected: rgba(0, 0, 0, 0.3);
  --background-color-disabled: rgba(0, 0, 0, 0.6);
  --background-color-highlighted: rgba(0, 0, 0, 0.1);
  --background-color-item-popup: rgba(230, 230, 230, 1);
  /* =======================================

      STYLE

  ======================================= */
  --border-radius: 5px;
  --border-radius-small: 2px;
  --border-width: 1px;
  --border-color-strong: rgba(0, 0, 0, 0.6);
  --border: 1px solid rgba(0, 0, 0, 0.3);
  --shadow-color: var(--color-header);
  --shadow: 0px 0px 10px var(--shadow-color);
  --inner-shadow: inset 0px 0px 10px var(--shadow-color);
  --inner-shadow-big: inset 0px 0px 20px var(--shadow-color);
  --padding: 10px;
  --padding-small: 5px;
  --padding-smaller: 3px;
  --margin: 5px;
  --margin-big: 10px;
  --margin-small: 3px;
  /* =======================================

      ANIMATIONS

  ======================================= */
  --transition: 0.15s all ease-in-out;
  --fast-transition: 0.075s all ease-in-out;
  --slow-transition: 0.3s all ease-in-out; }

@media (prefers-color-scheme: dark) {
  :root {
    /* =======================================

        TEXT

    ======================================= */
    --color: white;
    --color-header: rgba(0, 208, 214);
    --color-header-fine: rgba(0, 208, 214, 0.6);
    --color-header-raw: 0, 208, 214;
    --color-secondary: black;
    --color-fine: rgba(255, 255, 255, 0.6);
    --text-bigger: 28px;
    --text-big: 22px;
    --text-normal: 15px;
    /* =======================================

        COLORS

    ======================================= */
    --success: rgb(25, 145, 14);
    --warning: rgba(255, 251, 33, 1);
    --error: rgba(255, 64, 80, 1);
    --disabled: rgba(100, 100, 100, 0.6);
    --background-color: black;
    --background-color-item: rgb(33, 44, 44);
    --background-color-hover: rgba(255, 255, 255, 0.2);
    --background-color-selected: rgba(255, 255, 255, 0.3);
    --background-color-disabled: rgba(0, 0, 0, 0.6);
    --background-color-highlighted: rgba(255, 255, 255, 0.1);
    --background-color-item-popup: rgba(100, 100, 100, 1);
    /* =======================================

        STYLE

    ======================================= */
    --border-radius: 5px;
    --border-radius-small: 2px;
    --border-width: 1px;
    --border-color-strong: rgba(255, 255, 255, 0.6);
    --border: 1px solid rgba(255, 255, 255, 0.3);
    --shadow-color: var(--color-header);
    --shadow: 0px 0px 2px var(--shadow-color);
    --inner-shadow: inset 0px 0px 2px var(--shadow-color);
    --padding-big: 15px;
    --padding: 10px;
    --padding-small: 5px;
    --padding-smaller: 3px;
    --margin: 5px;
    --margin-big: 10px;
    --margin-small: 3px;
    /* =======================================

        ANIMATIONS

    ======================================= */
    --transition: 0.15s all ease-in-out;
    --fast-transition: 0.075s all ease-in-out;
    --slow-transition: 0.3s all ease-in-out; } }

.form-input {
  width: 100%;
  margin-bottom: var(--margin-big);
  display: flex;
  flex-direction: column;
  position: relative; }
  .form-input .input-wrapper {
    display: flex;
    justify-content: center; }
  .form-input.form-input-readonly .input-wrapper > .input-field {
    background-color: var(--background-color-highlighted);
    border: none; }
    .form-input.form-input-readonly .input-wrapper > .input-field input:hover {
      background-color: transparent; }
  .form-input.form-input-invalid .input-wrapper > .input-field {
    border: var(--border-width) solid var(--error); }
  .form-input .input-label {
    margin-bottom: var(--margin-small);
    margin-left: var(--margin);
    margin-right: var(--margin);
    color: var(--color-header-fine); }
  .form-input:last-child {
    margin-bottom: 0; }

.input-button {
  width: auto;
  display: inline-flex;
  margin: var(--margin); }
  .input-button .input-field input {
    cursor: pointer; }

.input-checkbox {
  width: auto;
  display: inline-flex; }

.input-radio {
  width: auto;
  display: inline-flex; }

:root {
  /* =======================================

      TEXT

  ======================================= */
  --color: black;
  --color-header: rgba(0, 170, 250);
  --color-header-fine: rgba(0, 170, 250, 0.6);
  --color-header-raw: 0, 208, 214;
  --color-secondary: black;
  --color-fine: rgba(0, 0, 0, 0.6);
  --text-bigger: 28px;
  --text-big: 22px;
  --text-normal: 15px;
  /* =======================================

      COLORS

  ======================================= */
  --success: rgb(25, 145, 14);
  --warning: rgba(255, 251, 33, 1);
  --error: rgba(255, 64, 80, 1);
  --disabled: rgba(100, 100, 100, 0.6);
  --background-color: rgb(230, 255, 255);
  --background-color-item: white;
  --background-color-hover: rgba(0, 0, 0, 0.2);
  --background-color-selected: rgba(0, 0, 0, 0.3);
  --background-color-disabled: rgba(0, 0, 0, 0.6);
  --background-color-highlighted: rgba(0, 0, 0, 0.1);
  --background-color-item-popup: rgba(230, 230, 230, 1);
  /* =======================================

      STYLE

  ======================================= */
  --border-radius: 5px;
  --border-radius-small: 2px;
  --border-width: 1px;
  --border-color-strong: rgba(0, 0, 0, 0.6);
  --border: 1px solid rgba(0, 0, 0, 0.3);
  --shadow-color: var(--color-header);
  --shadow: 0px 0px 10px var(--shadow-color);
  --inner-shadow: inset 0px 0px 10px var(--shadow-color);
  --inner-shadow-big: inset 0px 0px 20px var(--shadow-color);
  --padding: 10px;
  --padding-small: 5px;
  --padding-smaller: 3px;
  --margin: 5px;
  --margin-big: 10px;
  --margin-small: 3px;
  /* =======================================

      ANIMATIONS

  ======================================= */
  --transition: 0.15s all ease-in-out;
  --fast-transition: 0.075s all ease-in-out;
  --slow-transition: 0.3s all ease-in-out; }

@media (prefers-color-scheme: dark) {
  :root {
    /* =======================================

        TEXT

    ======================================= */
    --color: white;
    --color-header: rgba(0, 208, 214);
    --color-header-fine: rgba(0, 208, 214, 0.6);
    --color-header-raw: 0, 208, 214;
    --color-secondary: black;
    --color-fine: rgba(255, 255, 255, 0.6);
    --text-bigger: 28px;
    --text-big: 22px;
    --text-normal: 15px;
    /* =======================================

        COLORS

    ======================================= */
    --success: rgb(25, 145, 14);
    --warning: rgba(255, 251, 33, 1);
    --error: rgba(255, 64, 80, 1);
    --disabled: rgba(100, 100, 100, 0.6);
    --background-color: black;
    --background-color-item: rgb(33, 44, 44);
    --background-color-hover: rgba(255, 255, 255, 0.2);
    --background-color-selected: rgba(255, 255, 255, 0.3);
    --background-color-disabled: rgba(0, 0, 0, 0.6);
    --background-color-highlighted: rgba(255, 255, 255, 0.1);
    --background-color-item-popup: rgba(100, 100, 100, 1);
    /* =======================================

        STYLE

    ======================================= */
    --border-radius: 5px;
    --border-radius-small: 2px;
    --border-width: 1px;
    --border-color-strong: rgba(255, 255, 255, 0.6);
    --border: 1px solid rgba(255, 255, 255, 0.3);
    --shadow-color: var(--color-header);
    --shadow: 0px 0px 2px var(--shadow-color);
    --inner-shadow: inset 0px 0px 2px var(--shadow-color);
    --padding-big: 15px;
    --padding: 10px;
    --padding-small: 5px;
    --padding-smaller: 3px;
    --margin: 5px;
    --margin-big: 10px;
    --margin-small: 3px;
    /* =======================================

        ANIMATIONS

    ======================================= */
    --transition: 0.15s all ease-in-out;
    --fast-transition: 0.075s all ease-in-out;
    --slow-transition: 0.3s all ease-in-out; } }

.vertical-navigation {
  background-color: var(--background-color-item);
  height: 100%;
  width: 75px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: auto; }
  .vertical-navigation .vertical-navigation-top,
  .vertical-navigation .vertical-navigation-bottom {
    width: 100%; }
  .vertical-navigation .vertical-navigation-group {
    border-top: var(--border); }
    .vertical-navigation .vertical-navigation-group .vertical-navigation-group-title {
      text-align: center;
      color: var(--color-header);
      padding: var(--padding);
      border-bottom: var(--border); }
  .vertical-navigation .vertical-navigation-item {
    display: block; }
    .vertical-navigation .vertical-navigation-item.item-active .vertical-navigation-item-button {
      background-color: var(--background-color-highlighted);
      box-shadow: var(--inner-shadow); }
    .vertical-navigation .vertical-navigation-item .vertical-navigation-item-button {
      cursor: pointer;
      width: 100%;
      transition: var(--transition);
      display: flex;
      padding: var(--padding) 0;
      justify-content: center;
      align-items: center;
      color: var(--color); }
      .vertical-navigation .vertical-navigation-item .vertical-navigation-item-button:hover {
        background-color: var(--background-color-hover); }
  .vertical-navigation .vertical-navigation-top {
    align-self: flex-start; }
  .vertical-navigation .vertical-navigation-bottom {
    align-self: flex-end; }

:root {
  /* =======================================

      TEXT

  ======================================= */
  --color: black;
  --color-header: rgba(0, 170, 250);
  --color-header-fine: rgba(0, 170, 250, 0.6);
  --color-header-raw: 0, 208, 214;
  --color-secondary: black;
  --color-fine: rgba(0, 0, 0, 0.6);
  --text-bigger: 28px;
  --text-big: 22px;
  --text-normal: 15px;
  /* =======================================

      COLORS

  ======================================= */
  --success: rgb(25, 145, 14);
  --warning: rgba(255, 251, 33, 1);
  --error: rgba(255, 64, 80, 1);
  --disabled: rgba(100, 100, 100, 0.6);
  --background-color: rgb(230, 255, 255);
  --background-color-item: white;
  --background-color-hover: rgba(0, 0, 0, 0.2);
  --background-color-selected: rgba(0, 0, 0, 0.3);
  --background-color-disabled: rgba(0, 0, 0, 0.6);
  --background-color-highlighted: rgba(0, 0, 0, 0.1);
  --background-color-item-popup: rgba(230, 230, 230, 1);
  /* =======================================

      STYLE

  ======================================= */
  --border-radius: 5px;
  --border-radius-small: 2px;
  --border-width: 1px;
  --border-color-strong: rgba(0, 0, 0, 0.6);
  --border: 1px solid rgba(0, 0, 0, 0.3);
  --shadow-color: var(--color-header);
  --shadow: 0px 0px 10px var(--shadow-color);
  --inner-shadow: inset 0px 0px 10px var(--shadow-color);
  --inner-shadow-big: inset 0px 0px 20px var(--shadow-color);
  --padding: 10px;
  --padding-small: 5px;
  --padding-smaller: 3px;
  --margin: 5px;
  --margin-big: 10px;
  --margin-small: 3px;
  /* =======================================

      ANIMATIONS

  ======================================= */
  --transition: 0.15s all ease-in-out;
  --fast-transition: 0.075s all ease-in-out;
  --slow-transition: 0.3s all ease-in-out; }

@media (prefers-color-scheme: dark) {
  :root {
    /* =======================================

        TEXT

    ======================================= */
    --color: white;
    --color-header: rgba(0, 208, 214);
    --color-header-fine: rgba(0, 208, 214, 0.6);
    --color-header-raw: 0, 208, 214;
    --color-secondary: black;
    --color-fine: rgba(255, 255, 255, 0.6);
    --text-bigger: 28px;
    --text-big: 22px;
    --text-normal: 15px;
    /* =======================================

        COLORS

    ======================================= */
    --success: rgb(25, 145, 14);
    --warning: rgba(255, 251, 33, 1);
    --error: rgba(255, 64, 80, 1);
    --disabled: rgba(100, 100, 100, 0.6);
    --background-color: black;
    --background-color-item: rgb(33, 44, 44);
    --background-color-hover: rgba(255, 255, 255, 0.2);
    --background-color-selected: rgba(255, 255, 255, 0.3);
    --background-color-disabled: rgba(0, 0, 0, 0.6);
    --background-color-highlighted: rgba(255, 255, 255, 0.1);
    --background-color-item-popup: rgba(100, 100, 100, 1);
    /* =======================================

        STYLE

    ======================================= */
    --border-radius: 5px;
    --border-radius-small: 2px;
    --border-width: 1px;
    --border-color-strong: rgba(255, 255, 255, 0.6);
    --border: 1px solid rgba(255, 255, 255, 0.3);
    --shadow-color: var(--color-header);
    --shadow: 0px 0px 2px var(--shadow-color);
    --inner-shadow: inset 0px 0px 2px var(--shadow-color);
    --padding-big: 15px;
    --padding: 10px;
    --padding-small: 5px;
    --padding-smaller: 3px;
    --margin: 5px;
    --margin-big: 10px;
    --margin-small: 3px;
    /* =======================================

        ANIMATIONS

    ======================================= */
    --transition: 0.15s all ease-in-out;
    --fast-transition: 0.075s all ease-in-out;
    --slow-transition: 0.3s all ease-in-out; } }

.form {
  text-align: center; }

:root {
  /* =======================================

      TEXT

  ======================================= */
  --color: black;
  --color-header: rgba(0, 170, 250);
  --color-header-fine: rgba(0, 170, 250, 0.6);
  --color-header-raw: 0, 208, 214;
  --color-secondary: black;
  --color-fine: rgba(0, 0, 0, 0.6);
  --text-bigger: 28px;
  --text-big: 22px;
  --text-normal: 15px;
  /* =======================================

      COLORS

  ======================================= */
  --success: rgb(25, 145, 14);
  --warning: rgba(255, 251, 33, 1);
  --error: rgba(255, 64, 80, 1);
  --disabled: rgba(100, 100, 100, 0.6);
  --background-color: rgb(230, 255, 255);
  --background-color-item: white;
  --background-color-hover: rgba(0, 0, 0, 0.2);
  --background-color-selected: rgba(0, 0, 0, 0.3);
  --background-color-disabled: rgba(0, 0, 0, 0.6);
  --background-color-highlighted: rgba(0, 0, 0, 0.1);
  --background-color-item-popup: rgba(230, 230, 230, 1);
  /* =======================================

      STYLE

  ======================================= */
  --border-radius: 5px;
  --border-radius-small: 2px;
  --border-width: 1px;
  --border-color-strong: rgba(0, 0, 0, 0.6);
  --border: 1px solid rgba(0, 0, 0, 0.3);
  --shadow-color: var(--color-header);
  --shadow: 0px 0px 10px var(--shadow-color);
  --inner-shadow: inset 0px 0px 10px var(--shadow-color);
  --inner-shadow-big: inset 0px 0px 20px var(--shadow-color);
  --padding: 10px;
  --padding-small: 5px;
  --padding-smaller: 3px;
  --margin: 5px;
  --margin-big: 10px;
  --margin-small: 3px;
  /* =======================================

      ANIMATIONS

  ======================================= */
  --transition: 0.15s all ease-in-out;
  --fast-transition: 0.075s all ease-in-out;
  --slow-transition: 0.3s all ease-in-out; }

@media (prefers-color-scheme: dark) {
  :root {
    /* =======================================

        TEXT

    ======================================= */
    --color: white;
    --color-header: rgba(0, 208, 214);
    --color-header-fine: rgba(0, 208, 214, 0.6);
    --color-header-raw: 0, 208, 214;
    --color-secondary: black;
    --color-fine: rgba(255, 255, 255, 0.6);
    --text-bigger: 28px;
    --text-big: 22px;
    --text-normal: 15px;
    /* =======================================

        COLORS

    ======================================= */
    --success: rgb(25, 145, 14);
    --warning: rgba(255, 251, 33, 1);
    --error: rgba(255, 64, 80, 1);
    --disabled: rgba(100, 100, 100, 0.6);
    --background-color: black;
    --background-color-item: rgb(33, 44, 44);
    --background-color-hover: rgba(255, 255, 255, 0.2);
    --background-color-selected: rgba(255, 255, 255, 0.3);
    --background-color-disabled: rgba(0, 0, 0, 0.6);
    --background-color-highlighted: rgba(255, 255, 255, 0.1);
    --background-color-item-popup: rgba(100, 100, 100, 1);
    /* =======================================

        STYLE

    ======================================= */
    --border-radius: 5px;
    --border-radius-small: 2px;
    --border-width: 1px;
    --border-color-strong: rgba(255, 255, 255, 0.6);
    --border: 1px solid rgba(255, 255, 255, 0.3);
    --shadow-color: var(--color-header);
    --shadow: 0px 0px 2px var(--shadow-color);
    --inner-shadow: inset 0px 0px 2px var(--shadow-color);
    --padding-big: 15px;
    --padding: 10px;
    --padding-small: 5px;
    --padding-smaller: 3px;
    --margin: 5px;
    --margin-big: 10px;
    --margin-small: 3px;
    /* =======================================

        ANIMATIONS

    ======================================= */
    --transition: 0.15s all ease-in-out;
    --fast-transition: 0.075s all ease-in-out;
    --slow-transition: 0.3s all ease-in-out; } }

.horizontal-navigation {
  width: calc(100% - var(--padding) * 2);
  background-color: var(--background-color-item);
  padding: var(--padding);
  display: flex;
  justify-content: flex-end;
  overflow: hidden; }
  @media (max-width: 500px) {
    .horizontal-navigation {
      justify-content: center;
      flex-direction: column; } }
  .horizontal-navigation .horizontal-navigation-title {
    flex-grow: 1;
    text-align: center;
    font-size: var(--text-big);
    display: flex;
    color: var(--color-header);
    justify-content: center;
    align-items: center; }
    @media (max-width: 500px) {
      .horizontal-navigation .horizontal-navigation-title {
        margin-bottom: var(--margin); } }
  .horizontal-navigation .horizontal-navigation-items {
    justify-self: flex-end;
    flex-shrink: 1; }
    @media (max-width: 500px) {
      .horizontal-navigation .horizontal-navigation-items {
        display: flex;
        justify-content: center; } }
    .horizontal-navigation .horizontal-navigation-items .horizontal-navigation-item {
      height: 100%;
      display: inline-flex;
      justify-content: center;
      color: var(--color);
      align-items: center;
      margin-right: var(--margin-big); }
      .horizontal-navigation .horizontal-navigation-items .horizontal-navigation-item:last-child {
        margin-right: 0; }
      .horizontal-navigation .horizontal-navigation-items .horizontal-navigation-item .horizontal-navigation-item-value {
        cursor: pointer;
        padding: var(--padding-small);
        transition: var(--transition);
        text-align: center;
        border-bottom: var(--border-width) solid transparent; }
        .horizontal-navigation .horizontal-navigation-items .horizontal-navigation-item .horizontal-navigation-item-value:hover {
          border-color: var(--border-color-strong); }
      .horizontal-navigation .horizontal-navigation-items .horizontal-navigation-item.item-active .horizontal-navigation-item-value {
        border-color: var(--border-color-strong); }

:root {
  /* =======================================

      TEXT

  ======================================= */
  --color: black;
  --color-header: rgba(0, 170, 250);
  --color-header-fine: rgba(0, 170, 250, 0.6);
  --color-header-raw: 0, 208, 214;
  --color-secondary: black;
  --color-fine: rgba(0, 0, 0, 0.6);
  --text-bigger: 28px;
  --text-big: 22px;
  --text-normal: 15px;
  /* =======================================

      COLORS

  ======================================= */
  --success: rgb(25, 145, 14);
  --warning: rgba(255, 251, 33, 1);
  --error: rgba(255, 64, 80, 1);
  --disabled: rgba(100, 100, 100, 0.6);
  --background-color: rgb(230, 255, 255);
  --background-color-item: white;
  --background-color-hover: rgba(0, 0, 0, 0.2);
  --background-color-selected: rgba(0, 0, 0, 0.3);
  --background-color-disabled: rgba(0, 0, 0, 0.6);
  --background-color-highlighted: rgba(0, 0, 0, 0.1);
  --background-color-item-popup: rgba(230, 230, 230, 1);
  /* =======================================

      STYLE

  ======================================= */
  --border-radius: 5px;
  --border-radius-small: 2px;
  --border-width: 1px;
  --border-color-strong: rgba(0, 0, 0, 0.6);
  --border: 1px solid rgba(0, 0, 0, 0.3);
  --shadow-color: var(--color-header);
  --shadow: 0px 0px 10px var(--shadow-color);
  --inner-shadow: inset 0px 0px 10px var(--shadow-color);
  --inner-shadow-big: inset 0px 0px 20px var(--shadow-color);
  --padding: 10px;
  --padding-small: 5px;
  --padding-smaller: 3px;
  --margin: 5px;
  --margin-big: 10px;
  --margin-small: 3px;
  /* =======================================

      ANIMATIONS

  ======================================= */
  --transition: 0.15s all ease-in-out;
  --fast-transition: 0.075s all ease-in-out;
  --slow-transition: 0.3s all ease-in-out; }

@media (prefers-color-scheme: dark) {
  :root {
    /* =======================================

        TEXT

    ======================================= */
    --color: white;
    --color-header: rgba(0, 208, 214);
    --color-header-fine: rgba(0, 208, 214, 0.6);
    --color-header-raw: 0, 208, 214;
    --color-secondary: black;
    --color-fine: rgba(255, 255, 255, 0.6);
    --text-bigger: 28px;
    --text-big: 22px;
    --text-normal: 15px;
    /* =======================================

        COLORS

    ======================================= */
    --success: rgb(25, 145, 14);
    --warning: rgba(255, 251, 33, 1);
    --error: rgba(255, 64, 80, 1);
    --disabled: rgba(100, 100, 100, 0.6);
    --background-color: black;
    --background-color-item: rgb(33, 44, 44);
    --background-color-hover: rgba(255, 255, 255, 0.2);
    --background-color-selected: rgba(255, 255, 255, 0.3);
    --background-color-disabled: rgba(0, 0, 0, 0.6);
    --background-color-highlighted: rgba(255, 255, 255, 0.1);
    --background-color-item-popup: rgba(100, 100, 100, 1);
    /* =======================================

        STYLE

    ======================================= */
    --border-radius: 5px;
    --border-radius-small: 2px;
    --border-width: 1px;
    --border-color-strong: rgba(255, 255, 255, 0.6);
    --border: 1px solid rgba(255, 255, 255, 0.3);
    --shadow-color: var(--color-header);
    --shadow: 0px 0px 2px var(--shadow-color);
    --inner-shadow: inset 0px 0px 2px var(--shadow-color);
    --padding-big: 15px;
    --padding: 10px;
    --padding-small: 5px;
    --padding-smaller: 3px;
    --margin: 5px;
    --margin-big: 10px;
    --margin-small: 3px;
    /* =======================================

        ANIMATIONS

    ======================================= */
    --transition: 0.15s all ease-in-out;
    --fast-transition: 0.075s all ease-in-out;
    --slow-transition: 0.3s all ease-in-out; } }

.popover {
  min-width: calc(100% - var(--padding) * 2);
  position: absolute;
  z-index: 2;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--background-color-item-popup);
  border-radius: var(--border-radius);
  padding: var(--padding);
  top: 100%;
  left: 0; }
  .popover .popover-footer {
    margin-top: 10px;
    display: flex;
    width: 100%;
    padding-top: 10px;
    justify-content: center;
    border-top: var(--border-width) solid var(--border-color-strong); }
  .popover .popover-content {
    width: 100%;
    max-height: 300px;
    overflow: auto; }
  .popover.height-auto .popover-content {
    max-height: none; }
  .popover.open {
    display: inline-flex; }
  .popover.no-padding {
    padding: 0;
    width: 100%; }
  .popover.width-auto {
    min-width: auto;
    width: auto; }

input {
  background-color: transparent;
  padding: var(--padding);
  border: none;
  width: calc(100% - var(--padding) * 2);
  color: var(--color);
  font-size: var(--text-normal);
  transition: var(--transition); }
  input:hover {
    background-color: var(--background-color-hover); }
  input:active, input:focus {
    outline: none;
    background-color: var(--background-color-highlighted); }

.input-field {
  background-color: transparent;
  border: var(--border-width) solid var(--border-color-strong);
  border-radius: var(--border-radius);
  width: 100%;
  overflow: hidden; }

.button-input-field {
  width: auto; }
  .button-input-field input {
    width: auto;
    height: 100%; }
  .button-input-field.button-default input {
    background-color: var(--background-color-highlighted); }
    .button-input-field.button-default input:hover {
      background-color: transparent; }
  .button-input-field.button-submit {
    border: var(--border-width) solid var(--success); }
    .button-input-field.button-submit input {
      background-color: var(--success); }
      .button-input-field.button-submit input:hover {
        background-color: transparent; }
  .button-input-field.button-cancel {
    border: var(--border-width) solid var(--error); }
    .button-input-field.button-cancel input {
      background-color: var(--error); }
      .button-input-field.button-cancel input:hover {
        background-color: transparent; }

.checkbox-input-field {
  border: none;
  width: auto; }
  .checkbox-input-field .checkbox-input-value {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }

.number-input-field {
  display: flex; }
  .number-input-field .number-input-value {
    flex-grow: 1;
    width: auto; }
  .number-input-field .number-input-buttons {
    flex-grow: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    border-left: var(--border-width) solid var(--border-color-strong); }
    .number-input-field .number-input-buttons button {
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      color: var(--color);
      cursor: pointer; }
      .number-input-field .number-input-buttons button i {
        font-size: var(--text-small);
        padding: var(--padding-smaller); }
      .number-input-field .number-input-buttons button:hover {
        background-color: var(--background-color-hover); }
      .number-input-field .number-input-buttons button:active, .number-input-field .number-input-buttons button:focus {
        outline: none; }

.radio-input-field {
  border: none;
  width: auto; }
  .radio-input-field .radio-input-value {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }

.select-input-field {
  display: flex;
  overflow: visible;
  transition: var(--transition); }
  .select-input-field:hover {
    background-color: var(--background-color-hover); }
  .select-input-field .select-input-dropdown {
    position: relative;
    width: 100%;
    cursor: pointer; }
    .select-input-field .select-input-dropdown .select-input-control {
      display: flex; }
      .select-input-field .select-input-dropdown .select-input-control .select-input-body-placeholder {
        flex-grow: 1;
        padding: var(--padding); }
      .select-input-field .select-input-dropdown .select-input-control .select-input-body-arrow-wrapper {
        flex-grow: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: var(--padding-small); }
    .select-input-field .select-input-dropdown .select-input-menu {
      position: absolute;
      top: 100%;
      z-index: 1;
      width: 100%;
      background-color: var(--background-color-item-popup);
      border-radius: var(--border-radius); }
      .select-input-field .select-input-dropdown .select-input-menu .select-input-body-option {
        padding: var(--padding);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: var(--transition); }
        .select-input-field .select-input-dropdown .select-input-menu .select-input-body-option:hover {
          background-color: var(--background-color-hover); }

.options-input-field {
  padding: var(--padding);
  width: calc(100% - var(--padding) * 2); }
  .options-input-field .options-input-value {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: var(--padding);
    margin-bottom: var(--margin);
    border-bottom: var(--border-width) solid var(--border-color-strong);
    justify-content: center;
    align-items: center; }
    .options-input-field .options-input-value .options-input-value-item {
      display: flex;
      margin: var(--margin-small);
      border: var(--border-width) solid var(--border-color-strong);
      cursor: pointer;
      border-radius: var(--border-radius);
      justify-content: center;
      align-items: center;
      padding: var(--padding-smaller); }
      .options-input-field .options-input-value .options-input-value-item .options-input-value-item-label {
        font-size: var(--text-normal); }
      .options-input-field .options-input-value .options-input-value-item .options-input-value-item-icon {
        font-size: var(--text-normal);
        margin-left: var(--margin-small); }
  .options-input-field .options-input-fields {
    display: flex;
    margin-bottom: var(--margin-big); }
    .options-input-field .options-input-fields .options-input-fields-sign {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 var(--padding-small); }
  .options-input-field .options-input-buttons {
    display: flex;
    justify-content: center;
    align-items: center; }
    .options-input-field .options-input-buttons * {
      color: var(--color);
      padding: var(--padding-small);
      cursor: pointer;
      transition: var(--transition); }
    .options-input-field .options-input-buttons .options-input-buttons-add {
      border: var(--border-width) solid var(--success);
      background-color: var(--success);
      border-radius: var(--border-radius); }
      .options-input-field .options-input-buttons .options-input-buttons-add:hover {
        background-color: transparent; }
    .options-input-field .options-input-buttons .options-input-buttons-cancel {
      border: var(--border-width) solid var(--error);
      background-color: var(--error);
      border-radius: var(--border-radius);
      margin-left: var(--margin-big); }
      .options-input-field .options-input-buttons .options-input-buttons-cancel:hover {
        background-color: transparent; }

.tag-input-field {
  padding: var(--padding);
  position: relative;
  overflow: visible;
  width: calc(100% - var(--padding) * 2); }
  .tag-input-field .tag-input-items {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: var(--padding);
    margin-bottom: var(--margin);
    border-bottom: var(--border-width) solid var(--border-color-strong);
    flex-wrap: wrap; }
    .tag-input-field .tag-input-items .tag-input-items-item {
      display: flex;
      justify-content: center;
      align-items: center;
      border: var(--border-width) solid var(--border-color-strong);
      border-radius: var(--border-radius);
      padding: var(--padding-smaller);
      margin: var(-margin-small);
      cursor: pointer; }
      .tag-input-field .tag-input-items .tag-input-items-item * {
        font-size: var(--text-normal); }
  .tag-input-field .tag-input-datalist {
    position: absolute;
    background-color: var(--background-color-item-popup);
    width: calc(100% - var(--padding) * 2);
    z-index: 2;
    border-radius: var(--border-radius);
    display: none;
    flex-direction: column;
    justify-content: center; }
    .tag-input-field .tag-input-datalist .tag-input-datalist-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: var(--padding-small);
      cursor: pointer;
      transition: var(--transition); }
      .tag-input-field .tag-input-datalist .tag-input-datalist-item:hover, .tag-input-field .tag-input-datalist .tag-input-datalist-item.focused {
        background-color: var(--background-color-hover); }
    .tag-input-field .tag-input-datalist.open {
      display: flex; }

.radio-group-input-field {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .radio-group-input-field .radio-group-input-item {
    display: flex;
    flex-direction: column;
    padding: var(--padding); }
    .radio-group-input-field .radio-group-input-item .radio-group-input-item-value {
      display: flex;
      justify-content: center; }

.searchselect-input-field .searchselect-input-datalist-item {
  cursor: pointer;
  display: flex;
  padding: var(--padding-small);
  justify-content: center; }
  .searchselect-input-field .searchselect-input-datalist-item.focused {
    background-color: var(--background-color-selected); }
  .searchselect-input-field .searchselect-input-datalist-item:hover {
    background-color: var(--background-color-hover); }

.time-panel {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: var(--padding); }
  .time-panel .time-panel-splitter {
    padding: 0 var(--padding); }
  .time-panel .time-selector-part {
    display: flex;
    flex-direction: column;
    width: 50px; }
    .time-panel .time-selector-part .part-carussel {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: var(--padding-small);
      height: 80px;
      overflow-y: hidden;
      position: relative; }
      .time-panel .time-selector-part .part-carussel .part-item {
        padding: var(--padding-small) 0;
        height: 20px;
        transition: var(--fast-transition); }
      .time-panel .time-selector-part .part-carussel .part-blur-top {
        position: absolute;
        backdrop-filter: blur(1px);
        width: 100%;
        height: 27px;
        top: 5px;
        z-index: 1; }
      .time-panel .time-selector-part .part-carussel .part-blur-bottom {
        position: absolute;
        backdrop-filter: blur(1px);
        width: 100%;
        height: 27px;
        bottom: 5px;
        z-index: 1; }
      .time-panel .time-selector-part .part-carussel .part-button-hidden-top {
        margin-top: -25px; }
    .time-panel .time-selector-part .part-button {
      cursor: pointer;
      transition: var(--transition);
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center; }
      .time-panel .time-selector-part .part-button:hover {
        background-color: var(--background-color-hover); }

.date-time-panel {
  display: inline-flex;
  flex-direction: column; }
  .date-time-panel .date-time-panel-top {
    display: flex;
    justify-content: center;
    align-items: center; }
    .date-time-panel .date-time-panel-top .date-time-panel-btn.active {
      background-color: var(--background-color-selected); }
      .date-time-panel .date-time-panel-top .date-time-panel-btn.active:hover {
        background-color: var(--background-color-hover); }
  .date-time-panel .date-time-panel-value {
    color: var(--color-fine);
    display: flex;
    justify-content: center;
    padding: var(--padding-small); }

.date-panel {
  display: inline-flex;
  flex-direction: column;
  overflow: hidden; }
  .date-panel .date-panel-top {
    display: grid;
    grid-template-areas: "left view right";
    grid-template-columns: 70px auto 70px;
    grid-template-rows: auto; }
    .date-panel .date-panel-top .date-panel-top-nav-left {
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      padding: var(--padding);
      width: 50px;
      grid-area: left; }
      .date-panel .date-panel-top .date-panel-top-nav-left .date-panel-top-nav-left-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: var(--transition);
        color: var(--color);
        padding: var(--padding-small);
        outline: none; }
        .date-panel .date-panel-top .date-panel-top-nav-left .date-panel-top-nav-left-btn:hover {
          background-color: var(--background-color-hover); }
    .date-panel .date-panel-top .date-panel-top-view {
      padding: var(--padding);
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      grid-area: view; }
      .date-panel .date-panel-top .date-panel-top-view .date-panel-top-view-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: var(--transition);
        color: var(--color);
        padding: var(--padding-small);
        height: 37px;
        outline: none; }
        .date-panel .date-panel-top .date-panel-top-view .date-panel-top-view-btn:hover {
          background-color: var(--background-color-hover); }
    .date-panel .date-panel-top .date-panel-top-nav-right {
      padding: var(--padding);
      overflow: hidden;
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      width: 50px;
      grid-area: right; }
      .date-panel .date-panel-top .date-panel-top-nav-right .date-panel-top-nav-right-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: var(--transition);
        color: var(--color);
        padding: var(--padding-small);
        outline: none; }
        .date-panel .date-panel-top .date-panel-top-nav-right .date-panel-top-nav-right-btn:hover {
          background-color: var(--background-color-hover); }
  .date-panel .date-panel-current {
    display: flex;
    justify-content: center;
    color: var(--color-fine);
    margin-bottom: var(--margin-big); }
  .date-panel .date-panel-content {
    display: flex;
    justify-content: center;
    align-items: center; }
    .date-panel .date-panel-content .day-picker {
      display: grid;
      grid-template-areas: "hidden weekdays" "weeknumbers days";
      margin-bottom: var(--margin-big); }
      .date-panel .date-panel-content .day-picker .day-picker-weekdays {
        grid-area: weekdays;
        display: flex;
        flex-direction: row;
        border-bottom: var(--border-width) solid var(--border-color-strong); }
        .date-panel .date-panel-content .day-picker .day-picker-weekdays .day-picker-weekday {
          padding: calc(var(--padding-small) + var(--border-width));
          width: 30px;
          display: flex;
          justify-content: center; }
      .date-panel .date-panel-content .day-picker .day-picker-weeknumbers {
        grid-area: weeknumbers;
        border-right: var(--border-width) solid var(--border-color-strong); }
        .date-panel .date-panel-content .day-picker .day-picker-weeknumbers .day-picker-weeknumber {
          width: 30px;
          padding: var(--padding-small);
          display: flex;
          justify-content: center; }
      .date-panel .date-panel-content .day-picker .day-picker-days {
        grid-area: days;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 300px;
        align-items: center; }
        .date-panel .date-panel-content .day-picker .day-picker-days .day-picker-day {
          width: 30px;
          padding: var(--padding-small);
          display: flex;
          justify-content: center;
          transition: var(--transition);
          cursor: pointer;
          border: var(--border-width) solid transparent; }
          .date-panel .date-panel-content .day-picker .day-picker-days .day-picker-day.day-outgrayed {
            background-color: var(--disabled);
            color: var(--color-fine); }
          .date-panel .date-panel-content .day-picker .day-picker-days .day-picker-day.day-selected {
            background-color: var(--background-color-selected);
            border: var(--border-width) solid var(--border-color-strong); }
          .date-panel .date-panel-content .day-picker .day-picker-days .day-picker-day:hover {
            background-color: var(--background-color-hover); }
          .date-panel .date-panel-content .day-picker .day-picker-days .day-picker-day.day-not-selectable {
            color: var(--disabled);
            background-color: transparent; }
    .date-panel .date-panel-content .month-picker {
      display: flex;
      flex-wrap: wrap;
      width: 200px;
      justify-content: center;
      align-items: center;
      padding: 0; }
      .date-panel .date-panel-content .month-picker .month-picker-month {
        margin: var(--margin);
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: var(--background-color-highlighted);
        border-radius: var(--border-radius-small);
        transition: var(--transition); }
        .date-panel .date-panel-content .month-picker .month-picker-month:hover {
          background-color: var(--background-color-hover); }
    .date-panel .date-panel-content .year-picker {
      display: flex;
      flex-wrap: wrap;
      width: 180px;
      justify-content: center;
      align-items: center; }
      .date-panel .date-panel-content .year-picker .year-picker-year {
        padding: var(--padding);
        margin: var(--margin);
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--background-color-highlighted);
        border-radius: var(--border-radius-small);
        cursor: pointer;
        transition: var(--transition); }
        .date-panel .date-panel-content .year-picker .year-picker-year:hover {
          background-color: var(--background-color-hover); }
